// @ts-ignore
import { defaults } from './defaultConfigs';

export const Localization = {
  ...defaults,
  assignedCs: 'Assigned CS',
  csId: 'CS ID',
  currencySymbol: 'Bs',
  currencyAbbrev: 'BOB',
  branch: 'Branch',
  accountBranch: 'Branch',
  branches: 'Branches',
  collectionSpecialist: 'Collection Specialists',
  principalPayment: 'Total Payment Received*',
  showPenaltyPayment: false,
  penaltyPayment: 'Penalty Payment*',
  csLeaderBoard: 'CS Leaderboard',
  branchLeaderBoard: 'Branch Leaderboard',
  collectionsBarChartTitle: 'Field Collections',
  accountsBarChartTitle: 'Field Accounts',
  collectionSpecialistPerformanceTitle: 'Collection Specialist Performance',
  branchListingHeader: 'Branch Listing',
  branchListingBreadcrumb: 'Branch Listing',
  csListingHeader: 'Collection Specialist Listing',
  csListingBreadcrumb: 'Collection Specialist Listing',
  csDetailsBreadcrumb: 'Specialist',
  logoFilename: 'Logo_saci.png',
  clientName: 'Saci',
  navbarBackgroundColor: '#34276E',
  showManuals: true,
  showAIManuals: false,
  showAPK: false,
  csManualLink: '',
  cashierManualLink: '',
  managerManualLink:
    'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/artha/manuals/SACI_Manager_UserManual_v4.9.0.pdf',
  aiManualLink: '',
  apkLink: process.env.APK_URL || '',
  eulaTextUrl: 'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/eula/EULA_Saci.txt',
  eulaVersionUrl: 'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/eula/EULA_Saci.json',
  privacyStatementUrl:
    'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/privacy-statement/mobile_application_privacy_statement.txt',
  privacyStatementVersion:
    'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/privacy-statement/mobile_application_privacy_statement.json',
  zipcodeLength: 4,
  zipcodeValidationMsg: 'Bolivia zipcode should have 4 characters. Ex: 0700, 1234',
  country: 'Bolivia',
  countryAbbrev: 'BO',
  transactionListingHeader: 'Transaction Listing',
  transactionListingBreadcrumb: 'Transaction Listing',
  activateTransactionListing: true,
  activateCreditDebitMemo: true,
  activatePPD: false,
  currencyLocale: 'en-US',
  hiddenPenaltyField: true,
  csReportDownload: true,
  cashierReportDownload: true,
  appId: '1:834347563222:web:a56a4526443849b79ab177',
  measurementId: 'G-GMRQZG44R2',
  showTranslation: true,
  recoveredAccountsLabel: 'ACCOUNTS VISITED',
  noOfPaymentsLabel: 'TOTAL NO. OF RECORDS',
  showCashierPage: false,
  displayClientCode: false,
  metricsToDisplay: [
    'mtd-collection-efficiency',
    'account-recovery-rate',
    'average-visits-per-day',
    'collections-accounts-barchart',
  ],
  cspStatsToHide: [],
  showSummaryReportDownload: false,
  collectionSpecialistItinerary: 'Collection Specialist Itinerary',
  eReceipt: false,
  tabularDashboard: false,
  showStaffRole: false,
  timezone: 'America/La_Paz',
};
