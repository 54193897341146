/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState, useCallback, useRef } from 'react';
import { Route, Switch, withRouter, useLocation } from 'react-router-dom';
import { LoadScript } from '@react-google-maps/api';
import { NoAuthTemplate } from 'themes';
import { Async, NotificationSwiper } from 'components/layouts';
import { useDispatch } from 'react-redux';
import { userCheck } from 'redux/auth/actions';
import ReduxToastr from 'react-redux-toastr';
import { AppContext } from 'helpers';
import moment from 'moment-timezone';
import NepaliDate from 'nepali-date';
import { Localization, MONTH_LIST, NEPAL_MONTH_LIST, NEPAL_TZ } from 'constant';
import getRoutes from './routes';
import Analytics from '../components/web-services/firebase';

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [month, setMonth] = useState<string>('');
  const [year, setYear] = useState<string>('');
  const [currentMonth, setCurrentMonth] = useState<string>('');
  const [currentYear, setCurrentYear] = useState<string>('');
  const [branch, setBranch] = useState('All');
  const [status, setStatus] = useState('Active');
  const [client, setClient] = useState('All');
  const [timezone, setTimezone] = useState<string>('');
  const [monthList, setMonthList] = useState<any>([]);
  const [localAuth, setLocalAuth] = useState<any>();
  const settings = JSON.parse(localStorage.getItem('tvs.settings') || '{}');
  const authData = JSON.parse(localStorage.getItem('tvs.token_data') || '{}');
  const [fromToDate, setFromToDate] = useState({
    type: 'today',
    f: moment().tz(Localization.timezone)?.format('yyyy-MM-DD'),
    t: moment().tz(Localization.timezone)?.format('yyyy-MM-DD'),
  });
  const [showCollectionsSubmenu, setShowCollectionsSubmenu] = useState<boolean>(false);
  const [showListingsSubmenu, setShowListingsSubmenu] = useState<boolean>(false);
  const [showReportsSubmenu, setShowReportsSubmenu] = useState<boolean>(false);
  const [selectedBranches, setSelectedBranches] = useState<any>([]);
  const [ptpFromToDate, setPtpFromToDate] = useState({
    type: 'none',
    f: '',
    t: '',
  });
  const [selectedStaffRoles, setSelectedStaffRoles] = useState<any>([]);
  const [selectedGeoAccuracies, setSelectedGeoAccuracies] = useState<any>([]);
  const [selectedPaymentNotes, setSelectedPaymentNotes] = useState<any>([]);
  const [selectedDAUDate, setSelectedDAUDate] = useState(moment().tz(Localization.timezone).format('YYYY-MM-DD'));
  const [group, setGroup] = useState('All');
  const [userGroup, setUserGroup] = useState('All');
  const [selectedDate, setSelectedDate] = useState(timezone === NEPAL_TZ ? new NepaliDate() : new Date());
  const [collectionCycle, setCollectionCycle] = useState({
    from: '',
    to: '',
    month: 0,
    year: 0,
  });
  const [loading, setLoading] = useState(true);
  const [notificationLoaded, setNotificationLoaded] = useState(false);
  const [sensitiveNotificationLoaded, setSensitiveNotificationLoaded] = useState(false);
  const [selectedCashierSpecialistDate, setSelectedCashierSpecialistDate] = useState(new Date());

  useEffect(() => {
    if (Analytics.analyticsEnabled) {
      Analytics.logAnalyticsEvent(Analytics.EVENT_TYPE.general.appLoad, {}, {});
    }
    if (location.hash === '#help') {
      (window as any).zE('webWidget', 'open');
    }

    const updateCollectionCycle = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/system-date`);

        const result = await response.json();
        setCollectionCycle({
          from: result?.data?.first,
          to: result?.data?.last,
          month: result?.data?.month_index,
          year: result?.data?.year,
        });

        setMonth(String(result?.data?.month_index));
        setYear(String(result?.data?.year));
        setCurrentMonth(String(result?.data?.month_index));
        setCurrentYear(String(result?.data?.year));
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };

    updateCollectionCycle();
  }, []);

  useEffect(() => {
    if ((authData && !localAuth) || (authData && authData.acc_token !== localAuth.acc_token)) {
      setLocalAuth(authData);
      if (authData?.role && authData?.role === 'A') {
        setBranch(authData.branch);
      }
    }
  }, [authData]);

  useEffect(() => {
    if (settings.timezone) {
      setTimezone(settings.timezone);
    }
  }, [settings]);

  useEffect(() => {
    let currentTime: any = {};
    if (timezone === NEPAL_TZ) {
      const now = new NepaliDate();
      currentTime = {
        currentMonth: now.format('MM'),
        currentYear: now.format('YYYY'),
      };
      setMonthList(NEPAL_MONTH_LIST);
    } else {
      currentTime = {
        currentMonth: moment().format('MM'),
        currentYear: moment().format('Y'),
      };
      setMonthList(MONTH_LIST);
    }
  }, [timezone]);

  useEffect(() => {
    if (timezone === NEPAL_TZ) {
      // prettier-ignore
      if (collectionCycle?.month === Number(month) && collectionCycle?.year === Number(year)) { //eslint-disable-line 
        setSelectedDate(new NepaliDate());
      } else {
        setSelectedDate(
          new NepaliDate(collectionCycle?.year, collectionCycle?.month, new NepaliDate(collectionCycle?.from).getDate()),
        );
      }
    } else {
      // prettier-ignore
      if (collectionCycle?.month === Number(month) && collectionCycle?.year === Number(year)) { //eslint-disable-line 
        setSelectedDate(new Date());
      } else {
        setSelectedDate(
          new Date(collectionCycle?.year, collectionCycle?.month, new Date(collectionCycle?.from).getDate()),
        );
      }
    }
  }, [month, year]);

  const routeRenderer = (Layout: FC, pageComponent: string, noAuth = false) => {
    if (!noAuth) {
      dispatch(userCheck({}, '/login'));
    }
    return (
      <Layout>
        <Async page={pageComponent} />
      </Layout>
    );
  };

  const routeListRedenrer = useCallback(() => {
    const availabelRoutes = getRoutes(authData?.role);
    return availabelRoutes.map(({ noAuth, path, pageComponent, layout, isExact = false }, index) => {
      const template = layout || NoAuthTemplate;
      return (
        <Route
          key={`${pageComponent}-${index}-root`}
          path={path}
          exact={isExact}
          render={() => routeRenderer(template, pageComponent, noAuth)}
        />
      );
    });
  }, [authData]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AppContext.Provider
      value={{
        month,
        year,
        currentMonth,
        currentYear,
        setMonth,
        setYear,
        selectedDAUDate,
        setSelectedDAUDate,
        timezone,
        monthList,
        status,
        setStatus,
        branch,
        setBranch,
        client,
        setClient,
        fromToDate,
        setFromToDate,
        showCollectionsSubmenu,
        setShowCollectionsSubmenu,
        showListingsSubmenu,
        setShowListingsSubmenu,
        showReportsSubmenu,
        setShowReportsSubmenu,
        selectedBranches,
        setSelectedBranches,
        ptpFromToDate,
        setPtpFromToDate,
        selectedStaffRoles,
        setSelectedStaffRoles,
        selectedGeoAccuracies,
        setSelectedGeoAccuracies,
        selectedPaymentNotes,
        setSelectedPaymentNotes,
        group,
        setGroup,
        userGroup,
        setUserGroup,
        selectedDate,
        setSelectedDate,
        collectionCycle,
        setCollectionCycle,
        notificationLoaded,
        setNotificationLoaded,
        sensitiveNotificationLoaded,
        setSensitiveNotificationLoaded,
        selectedCashierSpecialistDate,
        setSelectedCashierSpecialistDate,
      }}
    >
      <LoadScript googleMapsApiKey="AIzaSyAgVnPN7QnkmxQPpomRTgyxQdps6s3rHIU">
        <div className="App">
          <Switch>{routeListRedenrer()}</Switch>
          <ReduxToastr
            timeOut={5000000000000}
            newestOnTop={false}
            preventDuplicates
            position="top-center"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick
          />
        </div>
      </LoadScript>
    </AppContext.Provider>
  );
};

export default withRouter(App);
